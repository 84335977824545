import React from "react"
import Header from "../components/header"
import { css } from "@emotion/react"
import { rhythm } from "../utils/typography"
import { Helmet } from "react-helmet"

export default function Layout({children}) {
  return (
    <div
      css={css`
        padding: ${rhythm(1)};
        padding-top: ${rhythm(0.5)};
      `}
    >
      <Helmet>
        <meta charSet="utf-8" />
        <title>blog.argann.io</title>
      </Helmet>
      <Header />
      <div
        css={css`
          padding: 0;
          height: 5px;
          background-color: #0C2461;
          width: 100%;
          margin-bottom: ${rhythm(1)};
        `}
      ></div>
      <div
        css={css`
          margin: 0 auto;
          max-width: 900px;
        `}
      >
        {children}
      </div>
    </div>
  )
}