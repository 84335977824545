import Typography from "typography"
import moragaTheme from "typography-theme-moraga"

moragaTheme.googleFonts = [
    {name: 'Montserrat', styles: ['400', '700'] },
    {name: 'Nunito', styles: ['400', '700'] }
]
moragaTheme.headerFontFamily = ["Nunito"]
moragaTheme.bodyFontFamily = ["Montserrat"]
moragaTheme.headerWeight = "500"
moragaTheme.baseFontSize = "20px"
moragaTheme.scaleRatio = 2

const typography = new Typography(moragaTheme)

export const { scale, rhythm, options } = typography
export default typography